<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">Cupons</h4>
          <div class="page-title-right">
            <b-button
              variant="success"
              v-b-tooltip.hover title="Adicionar Cupom"
              @click.prevent="showModal = !showModal"
            >
              <i class="bx bx-plus font-size-16 align-middle mr-2"></i>
              Adicionar
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body text-left">
            <h5>Cupons</h5>
            <hr/>
            <div class="table-responsive mb-0">
              <table class="table table-centered table-nowrap">
                <thead class="thead-light text-left">
                  <tr>
                    <th>ID</th>
                    <th>Código</th>
                    <th>Data de Início</th>
                    <th>Data Final</th>
                    <th>Quantidade</th>
                    <th>Ação</th>
                  </tr>
                </thead>
                <tbody class="text-left">
                  <tr v-for="data in cupons" :key="data.id">
                    <td>{{ data.id }}</td>
                    <td>{{ data.code }}</td>
                    <td>{{ data.date_inicio }}</td>
                    <td>{{ data.date_final }}</td>
                    <td>{{ data.quantidade }}</td>
                    <td>
                        <button class="btn btn-info ml-2" v-b-tooltip.hover.top title="Editar" @click.prevent="showModal = !showModal">
                          <i class="fas fa-pencil-alt text-white"></i>
                        </button>
                        <button class="btn btn-danger ml-2" v-b-tooltip.hover.top title="Remover">
                          <i class="fas fa-trash-alt text-white"></i>
                        </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showModal" title="Novo Cupom" centered>
        <div class="row">
          <div class="col-12">
            <label>
              <span class="mr-1 text-danger">*</span>
              Código
            </label>
            <b-form-group>
                <b-form-input
                    placeholder="Código do cupom"
                    for="text"
                ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-12 mt-1">
            <label>
              <span class="mr-1 text-danger">*</span>
              Quantidade
            </label>
            <b-form-group>
                <b-form-input
                    placeholder="Quantidade"
                    for="text"
                ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-12 mt-1">
            <label>
              Data de Início
            </label>
            <date-picker format="DD/MM/YYYY" placeholder="Data de Início" append-to-body lang="pt_BR" confirm></date-picker>
          </div>
          <div class="col-12 mt-3">
            <label>
              Data Final
            </label>
            <date-picker format="DD/MM/YYYY" placeholder="Data Final" append-to-body lang="pt_BR" confirm></date-picker>
          </div>
        </div>
        <template v-slot:modal-footer>
          <b-button variant="danger" @click="showModal = false">Cancelar</b-button>
          <b-button variant="success">Salvar</b-button>
        </template>
      </b-modal>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
export default {
  components: {
    DatePicker
  },
  data() {
    return {
      showModal: false,
      cupons: [
        {
          id: 1,
          date_inicio: '07 Oct, 2019',
          date_final: '07 Oct, 2019',
          code: 'GTR45545',
          quantidade: '10',
        },
         {
          id: 2,
          date_inicio: '07 Oct, 2019',
          date_final: '07 Oct, 2019',
          code: 'GTR45545',
          quantidade: '10',
        },
      ],
    }
  },
  created() {},
  methods: {},
}
</script>
